import {createStore} from 'vuex'
import mutations from "./mutations";
import actions from "./actions";
import app from './modules/app'
import category from "./modules/category";
import article from "./modules/article";
import user from './modules/user'
import company from "./modules/company";

const store = createStore({
  state: {
    count: 1
  },
  mutations,
  actions,
  modules: {
    app,
    category,
    article,
    user,
    company
  }
})

// store.registerModule('')

export default store
