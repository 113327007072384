import axios from "axios";
import {getToken} from "./global";
import qs from "qs";
import {Toast} from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_URL + '/api/',  // api的base_url
  timeout: 1000 * 60 * 5  // 请求超时时间
});

// service.defaults.headers.common['token'] = "111"

/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(config => {
  if (config.method === 'post' || config.method === 'patch' || config.method === 'put') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // 如果是上传的话的就啥也不处理否则进行qs编码
    if (!(config.upload != null && config.upload)) {
      config.data = qs.stringify(config.data)
    }
    // else {
    //   config.headers['Content-Type'] = 'application/json; charset=utf-8';
    // }
  }

  config.headers['token'] = getToken()
  return config;
}, error => {  //请求错误处理
  return Promise.reject(error)
});


function onError(e) {
  const isLogin = sessionStorage.getItem("login")

  if(e.response.status === 403 && isLogin !== null) {
    window.location.href = process.env.VUE_APP_URL + `/api/wechat/login?state=${encodeURIComponent(window.location.href)}`
  }
  if (e.response) {
    // 请求已发出，服务器返回的 http 状态码不是 2xx，例如：400，500，对应上面的 1
    console.info(e.response)
  } else if (e.request) {
    // 请求已发出，但没有收到响应，例如：断网，对应上面的 4
    console.info(e.request)
  } else {
    // 请求被取消或者发送请求时异常，对应上面的 2 & 3
    console.info('error', e.message)
  }
}


service.interceptors.response.use(response => {
  const {status, msg} = response.data
  if (undefined !== status && status !== 200 && null !== msg && msg !== '') {
    Toast(msg)
  }
  return response
}, error => {
  onError(error)
  console.error('网络请求错误:', error)
  if (error.response.status === 404) {
    Toast('请求资源不存在')
  }
  return Promise.reject(error);
})

export default service
