const state = {
  name: '引流神器'
}
const actions = {
  setAsync({commit}) {
    setTimeout(() => {
      commit('setName')
    }, 1000)
  },

}
const mutations = {
  setName(state) {
    state.name = '测试'
  }
}
const getters = {
  getName(state) {
    return state.name
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
