import {createApp} from 'vue'
import App from './App'
import router from './router'
import store from './store'
// import 'vant/lib/index.css'
// 引入vant css样式 https://vant-contrib.gitee.io/vant/#/zh-CN/quickstart
// Vant 中有个别组件是以函数的形式提供的，包括 Toast，Dialog，Notify 和 ImagePreview 组件。在使用函数组件时，unplugin-app-components 无法自动引入对应的样式，因此需要手动引入样式。
// Toast
import {
  Notify, ImagePreview,
  Col, Row, Toast, Divider, Lazyload, Button, Icon, Loading,
  Image as VanImage, Field, List, Cell, Sticky, CellGroup, Badge,
  Dialog, ActionSheet, Switch,
  Checkbox, CheckboxGroup,
  PullRefresh,
  ConfigProvider,
  Empty
} from 'vant';
// import 'vant/es/toast/style';
//
// // Dialog
// import 'vant/es/dialog/style';
// // Notify
// import 'vant/es/notify/style';
// // ImagePreview
// import 'vant/es/image-preview/style';
// // 这是个坑
// import 'vant/es/row/style'
// import 'vant/es/col/style'
// import 'vant/es/search/style'
// import 'vant/es/tabs/style'
// import 'vant/es/tab/style'
// import 'vant/es/tabbar/style'
// import 'vant/es/tabbar-item/style'
// import 'vant/lib/index.css';
// 引入自定义样式
import './assets/style/common.less'



const app = createApp(App)


app.use(store)
app.use(router)
// vant
app.use(Dialog)
app.use(Notify)
app.use(ImagePreview)

app.use(Col)
app.use(Row)
app.use(Toast)
app.use(Divider)
app.use(Lazyload, {lazyComponent: true})
app.use(Button)
app.use(Icon)
app.use(Loading)
app.use(VanImage)
app.use(Field)
app.use(List)
app.use(Cell)
app.use(Sticky)
app.use(CellGroup)
app.use(Badge)
app.use(ActionSheet)
app.use(Switch)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(PullRefresh)
app.use(ConfigProvider)
app.use(Empty)


app.mount('#app')



// window.addEventListener(
//   "error",
//   (e) => {
//     console.log(e);
//     //TODO:上报逻辑
//     return true;
//   },
//   true
// );
// // 处理未捕获的异常，主要是promise内部异常，统一抛给 onerror
// window.addEventListener("unhandledrejection", (e) => {
//   throw e.reason;
// });
// // 框架异常统一捕获
// vue.config.errorHandler = function(err, vm, info) {
//   //TODO:上报逻辑
//   console.log(err, vm, info);
// };
