import service from "../../utils/request";
import {get, post} from "../../utils/http";

const state = {
  user: {}, company: {}, category: {}, profile: {},
}
const getters = {
  isVip: (state) => {
    // if (null === state.user.vip || undefined === state.user.vip) {
    //   return false
    // }
    return state.user.vip
  }
}

const mutations = {}
const actions = {
  modifySubCategory(state, data) {
    return new Promise((resolve, reject) => {
      service({
        url: 'user/modify/sub', params: {sub_category: data.sub_category}, method: 'PATCH', // 只修改单一字段的时候使用patch
      }).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        }
      }).catch(reject)
    })
  }, // 获取我关注的品牌
  fetchSubCompany() {
    return new Promise((resolve, reject) => {
      service({url: 'tag', method: 'get'}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        }
      }).catch(reject)
    })
  }, addCompanyById(context, id) {
    return new Promise((resolve, reject) => {
      service.post('tag', {id: id}).then(response => {
        const msg = response.data.msg
        const result = response.data.status === 200
        resolve({result, msg})
      }).catch(reject)
    })
  }, info() {
    return new Promise((resolve, reject) => {
      get('user/info').then(response => {
        state.profile = response.data.profile ? response.data.profile : {}
        state.user = response.data.user
        state.category = response.data.category
        state.company = response.data.company
        resolve(response)
      }).catch(err => reject(err))
    })

  }, uploadAvatar(context, data) {
    return new Promise((resolve, reject) => {
      service.post('/user/upload/avatar', data, {upload: true}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  }, uploadWechatQrCode(context, data) {
    return new Promise((resolve, reject) => {
      service.post('/user/upload/wechat/qrcode', data, {upload: true}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          resolve(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  }, editorField(context, data) {
    return new Promise((resolve, reject) => {
      service.patch(`/user/profile/editor/${data.field}`, data).then(response => {
        // console.log(response.data.status)
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          resolve(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  }, microsite() {
    return new Promise((resolve, reject) => {
      service.get('user/shop/home').then(response => {
        // console.log(response.data.status)
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          resolve(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  }, getComment(context, data) {
    return get('/comment/fetch', data)
  }, addComment(context, data) {
    return post('/comment/add', data)
  }, getMicrosite(context, data) {
    return get('/microsite/fetch', data)
  }
}


export default {
  namespaced: true, state, mutations, actions, getters
}
