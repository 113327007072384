const user = [
  {
    path: '/path/test/user',
    name: 'TestUser',
    component: () => import('../views/Team/Index'),
    meta: {
      title: '测试自定义路由'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User/Home.vue'),
    meta: {
      title: '我的主页'
    }
  },
  {
    path: '/user/home',
    name: 'UserHome',
    meta: {title: '我的'},
    component: () => import('../views/User/Index.vue'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../views/Agreement/Index.vue'),
    meta: {
      title: '用户协议、隐私保护协议'
    }
  }
]

export default user
