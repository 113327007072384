<template>
  <router-view/>
</template>
<script setup>
</script>
<style lang="less">
// 把全局的box设置为border-box
* {
  box-sizing: border-box;
}

*::before, *::after {
  box-sizing: border-box;
}

body {
  font-size: 12px;
  background-color: #FFFEFE;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

/*隐藏滚动条*/
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
