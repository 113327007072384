export const team = [
  {
    path: '/team/manager/:id',
    name: 'TeamManager',
    meta: {title: '团队管理'},
    component: () => import('../views/Team/Index.vue'),

  },
  {
    path: '/team/toggle',
    name: 'TeamToggle',
    meta: {title: '切换团队'},
    component: () => import('../views/Team/ToggleTeam.vue')
  },

  // {
  //   path: '/team/join/:uid/:teamId',
  //   name: 'TeamJoin',
  //   meta: {title: '邀请加入'},
  //   component: () => import('../views/Team/Join.vue')
  // },
  {
    path: '/team/invitation/:id',
    name: 'TeamInvitationJoin',
    meta: {title: '邀请加入'},
    component: () => import('../views/Team/Invitation.vue')
  },
  {
    path: '/team/member/manager/:teamId',
    name: 'TeamMemberManager',
    meta: {title: '团队管理'},
    component: () => import('../views/Team/TeamMemberManager.vue')
  },
  {
    path: '/team/change/name/:id',
    name: 'TeamChangeName',
    meta: {title: '修改团队名称'},
    component: () => import('../views/Team/ChangeTeamName.vue')
  },


]
