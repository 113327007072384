import service from "../utils/request";

const actions = {
  uploadImage(context, data){
    return new Promise((resolve, reject) => {
      service.post('/uploads/image', data, {upload: true}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  },
  uploadVideo(context, data){
    return new Promise((resolve, reject) => {
      service.post('/uploads/video', data, {upload: true}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data.msg)
        }
      }).catch(err => reject(err))
    })
  }
}

export default actions
