import service from "../../utils/request";


const state = {
  data: []
}

const mutations = {
  setCategoryData(state, data) {
    state.data = data
  }
}

const actions = {
  findSubscriptCategory(context, options) {
    return new Promise(((resolve, reject) => {
      service.get(`category/user`, {params: {...options}}).then(response => {
        if (response.data.status === 200) {
          context.commit('setCategoryData', response.data.data)
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    }))
  },
  fetch(context, options) {
    return new Promise(((resolve, reject) => {
      service.get("category", {params: {...options}}).then(response => {
        if (response.data.status === 200) {
          context.commit('setCategoryData', response.data.data)
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    }))
  },
  // 获取分类列表及子列表
  treeList() {
    return new Promise(((resolve, reject) => {
      service.get("category/tree-list").then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    }))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
