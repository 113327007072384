import {createRouter, createWebHistory} from 'vue-router'
import {getToken} from "../utils/global";
import user from "./user";
import {team} from "./team";
import {slot} from "@/router/slot";

const routes = [
  {
    path: '/',
    redirect: {name: 'Home',},
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/Index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome/Index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/video/info/:id',
    name: 'VideoInfo',
    meta: {title: '视频'},
    component: () => import('../views/Video/Info.vue')
  },
  {
    path: '/video/share/:uid/:id',
    name: 'VideoShare',
    component: () => import('../views/Video/Share.vue'),
    meta: {
      title: '分享'
    }
  },
  {
    path: '/video/ad',
    name: 'VideoAd',
    meta: {title: '视频'},
    component: () => import('../views/Video/AdTemplate.vue')
  },

  {
    path: '/company',
    name: 'Company',
    meta: {title: '我的品牌'},
    component: () => import('../views/Company/Index.vue')
  },
  {
    path: '/client',
    name: 'Client',
    meta: {title: '客户'},
    component: () => import('../views/Client/Index.vue')
  },
  {
    path: '/client/list/:uid',
    name: 'ClientListUid',
    meta: {title: '客户'},
    component: () => import('../views/Client/List.vue')
  },
  {
    path: '/client/count/list/:id',
    name: 'ClientCountList',
    meta: {title: '推广分析'},
    component: () => import('../views/Client/ArticleCountList.vue')
  },

  {
    path: '/user/info',
    name: 'UserInfo',
    meta: {title: '我的'},
    component: () => import('../views/User/Info.vue'),
  },
  {
    path: '/user/profile/edit/:name',
    name: 'UserProfileEdit',
    meta: {title: '我的'},
    component: () => import('../views/User/Editor.vue'),
  },
  {
    path: '/user/member',
    name: 'UserMember',
    meta: {title: '高级会员'},
    component: () => import('../views/Member/Index.vue'),
  },
  {
    path: '/user/shop/:uid/:company',
    name: 'UserShop',
    meta: {title: '微站'},
    component: () => import('../views/User/Shop.vue'),
  },
  {
    path: '/user/shop/:uid/:company/profile',
    name: 'ShopProfile',
    meta: {title: '微站'},
    component: () => import('../views/User/ShopProfile.vue'),
  },
  {
    path: '/user/publish',
    name: 'ShopPublish',
    meta: {title: '我的发布'},
    component: () => import('../views/User/Publish.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/article/info/:id',
    name: 'ArticleInfo',
    component: () => import('../views/Article/ArticleInfo.vue'),
    meta: {
      title: '文章详情'
    }
  },
  {
    path: '/article/target/:id',
    name: 'ArticleTarget',
    component: () => import('../views/ArticleInfo/Index.vue'),
    meta: {
      title: '文章详情'
    }
  },
  {
    path: '/article/:id/editor',
    name: 'ArticleEditor',
    component: () => import('../views/Article/EditorTarget.vue'),
    meta: {
      title: '编辑文章'
    }
  },
  {
    path: '/article/help/add',
    name: 'ArticleAddHelp',
    component: () => import('../views/Article/ArticleHelpAdd.vue'),
    meta: {
      title: '添加文章'
    }
  },
  {
    path: '/article/add',
    name: 'ArticleAdd',
    component: () => import('../views/Article/AddArticle.vue'),
    meta: {
      title: '添加文章'
    }
  },
  {
    path: '/article/publish',
    name: 'ArticlePublish',
    component: () => import('../views/Article/Publish.vue'),
    meta: {
      title: '我的发布'
    }
  },
  {
    path: '/article/user/views/:id/:articleId',
    name: 'ArticleViews',
    component: () => import('../views/Article/ViewsContent.vue'),
    meta: {
      title: '查看客户文章'
    }
  },
  {
    path: '/article/ad/:id',
    name: 'ArticleAD',
    component: () => import('../views/AD/Index.vue'),
    meta: {
      title: '添加广告'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search/Index.vue'),
    meta: {
      title: '引流文章/视频'
    }
  },
  {
    path: '/category/settings',
    name: 'CategorySettings',
    component: () => import('../views/Category/Settings.vue'),
    meta: {
      title: '添加品牌行业'
    }
  },
  {
    path: '/company/settings',
    name: 'CompanySettings',
    component: () => import('../views/Company/Settings.vue'),
    meta: {
      title: '添加品牌行业'
    }
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../views/Favorites/Index.vue'),
    meta: {
      title: '我的收藏'
    }
  },
  {
    path: '/user/push',
    name: 'UserPush',
    component: () => import('../views/User/Push/Index.vue'),
    meta: {
      title: '推送'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/login/out',
    name: 'LoginOut',
    component: () => import('../views/Login/LoginOut.vue'),
    meta: {
      title: '退出登录'
    }
  },
  {
    path: '/share/:uid/:id',
    name: 'Share',
    component: () => import('../views/Share/Index.vue'),
    meta: {
      title: '分享'
    }
  },
  {
    path: '/share/subscribe/:id',
    name: 'ShareSubscribe',
    component: () => import('../views/Share/Subscribe.vue'),
    meta: {
      title: '分享'
    }
  },
  {
    path: '/micro/site/index',
    name: 'MicroSiteIndex',
    component: () => import('../views/MicroSite/Index.vue'),
    meta: {
      title: '我的微站'
    }
  },
  {
    path: '/micro/site/browse/:uid/:company',
    name: 'MicroSiteBrowse',
    component: () => import('../views/MicroSite/Browse.vue'),
    meta: {
      title: '我的微站'
    }
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('../views/Demo/Index.vue'),
    meta: {
      title: 'Demo'
    }
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/Pay/Index'),
    meta: {
      title: 'Pay'
    }
  },
  {
    path: '/feed/:article',
    name: 'Feed',
    component: () => import('../views/Feed/Index'),
    meta: {
      title: '投诉'
    }
  }
]

const mergeRouter = routes.concat(user, team, slot)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: mergeRouter,
  mode: 'history',
})


router.beforeEach((to, from, next) => {

  const title = to.meta && to.meta.title;

  if (title) {
    const index = document.title.indexOf('/')
    if (index !== -1) {
      document.title = document.title.substring(0, index)
    }
    document.title = to.path === '/home' ? document.title + '/视频' : title;
  }
  // 如果没有token则视为没有登录进行跳转
  const token = getToken()

  // 如果token == null and path != login
  if ((null == token || 'undefined' == token || 'null' === token) && to.path !== '/login') {
    const timestamp = (new Date()).valueOf()
    sessionStorage.setItem("login", "1")
    // console.log(process.env.VUE_APP_URL + `/api/wechat/login?state=${encodeURIComponent(window.location.href)}&timestamp=${timestamp}`)
    window.location.href = process.env.VUE_APP_URL + `/api/wechat/login?state=${encodeURIComponent(window.location.href)}&timestamp=${timestamp}`
  }

  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  if (isiOS && to.path !== location.pathname) {
    location.assign(to.fullPath) // 此处不可使用location.replace
  } else {
    next()
  }

});


export default router

