import service from "./request";
import {Toast} from "vant";


export const methods = {get: 'GET', post: 'POST'}
/**
 * 封装一个统计的方法request
 * @param url 请求的uri path
 * @param method 请求方法 默认GET
 * @param params 参数
 * @returns {Promise<unknown>}
 */
export const request = ({url, method = 'GET', params = {}}) => {
  return new Promise((resolve, reject) => {
    // 得到参数去请求数据
    service({url, method, params: {...params}}).then(response => {
      // 得到结果的时候进行
      const {status, msg, data} = response.data
      if (status !== HTTP_OK) {
        Toast(msg)
        reject(msg)
      } else {
        resolve(data)
      }
    })
  })
}

export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {params: {...params}}).then(response => {
      if (response.data.status === 200) {
        resolve(response.data)
      } else {
        reject(response)
      }

    }).catch(err => reject(err))
  })
}


export function post(url, param) {
  return new Promise((resolve, reject) => {
    service.post(url, {...param}).then(response => resolve(response)).catch(err => reject(err))
  })
}


export function postData(url, param){
  return service.post(url, {...param}).then(response=>response.data)
}


export function patch(url, param) {
  return new Promise((resolve, reject) => {
    service.patch(url, {...param}).then(response => resolve(response.data)).catch(err => reject(err))
  })
}

export function destroy(url, param) {
  return new Promise((resolve, reject) => {
    service.delete(url, {...param}).then(response => resolve(response.data)).catch(err => reject(err))
  })
}

export const HTTP_OK = 200
export default {get, post, HTTP_OK}
