import service from "../../utils/request";

const state = {
  data: [], // 存放内容
  article: {}
}

const mutations = {
  setData(state, data) {
    state.data = data
  },
  setArticle(state, article) {
    state.article = article
  },
}

const actions = {
  fetch(context, param) {
    return new Promise((resolve, reject) => {
      service({url: 'article/fetch', method: 'get', params: {...param}}).then(response => {
        if (response.data.status === 200) {
          context.commit('setData', response.data.data)
          resolve(response.data.data)
        }
      }).catch(reject)
    })
  },
  fetchAllByCategoryParentId(context, pid) {
    return new Promise((resolve, reject) => {
      service.get(`/article/fetchByPid/${pid}`, {params: {...pid}}).then(response => {
        if (response.data.status === 200) {
          context.commit('setData', response.data.data)
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    })
  },
  fetchById(context, id) {
    return new Promise((resolve, reject) => {
      service.get(`article/${id}`, {data: {id}}).then(response => {
        if (response.data.status === 200) {
          context.commit('setArticle', response.data.data)
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    })
  },
  search(context, keyword) {
    return new Promise((resolve, reject) => {
      console.log(keyword)
      service({
        url: 'article/search',
        method: 'get',
        params: {
          keyword
        }
      }).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        }
      }).catch(err => reject(err))
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
