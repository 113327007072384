import service from "../../utils/request";

const state = {}
const mutations = {}
const actions = {
  fetchByKeyword(context, keyword) {
    return new Promise((resolve, reject) => {
      service({url: 'company/fetchByKeyword', method: 'GET', params: {keyword}}).then(response => {
        if (response.data.status === 200) {
          resolve(response.data.data)
        }
      }).catch(reject)
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
