import Cookies from "js-cookie";
import store from "../store";
import {Toast} from "vant";
// import * as imageConversion from 'image-conversion';
import Compress from 'compress.js'

const compress = new Compress()
const maxUploadFileSize = parseInt(process.env.VUE_APP_MAX_UPLOAD_FILE_SIZE)

const BASE_URL = process.env.VUE_APP_URL
let loading = null


function showLoading(text = '加载中...') {
  loading = Toast.loading({
    message: text,
    forbidClick: true,
    loadingType: 'spinner',
    duration: 0,
  });
}

function getToken() {
  if (localStorage) {
    return localStorage.getItem("token")
  } else {
    return Cookies.get('token');
  }
}

function setToken(token) {
  if (localStorage) {
    localStorage.setItem("token", token)
  } else {
    // Create a cookie that expires 31 days from now, valid across the entire site:
    Cookies.set('token', token, {expires: 31, path: ''});
  }
}


/**
 * 上传图片
 * @param callback files[0]
 */
function uploadImage(callback) {
  // 上传文件
  const filetype = '.png, .jpeg, .webp, .jpg, .bpm, .gif'
  upload(filetype, callback)
}

function upload(filetype, callback) {
  // 模拟出一个input用于添加本地文件
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', filetype);
  input.click();
  input.onchange = function () {
    if (input.files != null && input.files.length > 0) {
      callback(input.files[0])
    } else {
      callback(null)
    }
  }
}

/**
 * 上传图片
 * @param callback files[0]
 */
function uploadVideo(callback) {

  // const right_type = new Array("avi","wmv","mpg","mpeg","mov","rm","ram","swf","flv","mp4","mp3","wma","avi","rm","rmvb","flv","mpg","mkv")
  // 上传文件
  const filetype = '.avi, .wmv, .mpg, .mpeg, .mov, .swf, .flv, .mp4, .avi, .rm, .rmvb, .flv, .mpg, .mkv'
  // console.log(filetype)
  upload(filetype, callback)
}


function checkPhone(phone) {
  // const phone = document.getElementById('phone').value;
  if (!(/^1(3|4|5|7|8)\d{9}$/.test(phone))) {
    return false;
  }
  return true
}

/**
 * 格式化时长
 */
function formatDuration(duration) {
  if (duration > 0 && duration < 60) {
    return duration + '秒'
  } else if (duration >= 60 && duration < 60 * 60) {
    const minute = parseInt(duration / 60)
    const second = duration % 60
    if (second > 0) {
      return minute + '分钟' + second + '秒'
    }
    return minute + '分钟'
  } else if (duration >= 60 * 60 && duration < 60 * 60 * 60) {
    const hour = parseInt(duration / 60 / 60)
    const minute = parseInt(duration / 60 % 60)
    const second = parseInt(duration / 60 / 60 % 60)
    return hour + '小时' + minute + '分钟' + second + '秒'
  } else {
    return 0 + '秒'
  }
}

function parseImag(uri) {
  return BASE_URL + uri
}


function copy(data) {
  // 存储传递过来的数据
  let OrderNumber = data;
  // 创建一个input 元素
  // createElement() 方法通过指定名称创建一个元素
  let newInput = document.createElement("input");
  // 讲存储的数据赋值给input的value值
  newInput.value = OrderNumber;
  // appendChild() 方法向节点添加最后一个子节点。
  document.body.appendChild(newInput);
  // 选中input元素中的文本
  // select() 方法用于选择该元素中的文本。
  newInput.select();
  // 执行浏览器复制命令
  //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
  document.execCommand("Copy");
  // 清空输入框
  newInput.remove();
}

function uploadImageFile(callback) {

  uploadImage(file => {

    showLoading('上传中...')
    // console.log(file)
    // 文件大小转换为MB * 百分比
    // const size = (file.size / 1024 / 1024)  * (0.6 * 100)
    // // console.log(size)
    // imageConversion.compressAccurately(file, size).then(blob=> {
    //   const formData = new window.FormData();
    //   formData.append('file', blob, file.name)
    //   store.dispatch('uploadImage', formData).then(data => {
    //     callback(data)
    //   })
    // })

    // console.log(file)
    const filename = file.name
    // const files = [file]
    // 这个好用压缩更小一些
    // compress.compress(files, {
    //   size: 1, // the max size in MB, defaults to 2MB
    //   quality: .3, // the quality of the image, max is 1,
    //   maxWidth: 720, // the max width of the output image, defaults to 1920px
    //   maxHeight: 1920, // the max height of the output image, defaults to 1920px
    //   resize: true, // defaults to true, set false if you do not want to resize the image width and height
    //   rotate: false, // See the rotation section below
    // }).then(results => {
    //   const img1 = results[0]
    //   const base64str = img1.data
    //   const imgExt = img1.ext
    //   const file = Compress.convertBase64ToFile(base64str, imgExt)
    //   // console.log(file)
    //
    //   const formData = new window.FormData();
    //   formData.append('file', file, filename)
    //   store.dispatch('uploadImage', formData).then(data => {
    //     loading.clear()
    //     callback(data)
    //   })
    // })


    compressImage(file).then(data => {
      const formData = new window.FormData();
      formData.append('file', data, filename)
      store.dispatch('uploadImage', formData).then(data => {
        loading.clear()
        callback(data)
      })
    })


    // imageConversion.compress(file, 0.2).then(blog => {
    //   console.log(blog)
    //   const formData = new window.FormData();
    //   formData.append('file', blog, file.name)
    //   store.dispatch('uploadImage', formData).then(data => {
    //     loading.clear()
    //     callback(data)
    //   })
    // })

    // const formData = new window.FormData();
    // formData.append('file', file, file.name)
    // store.dispatch('uploadImage', formData).then(data => {
    //   callback(data)
    // })
  })
}

async function compressImage(file) {
  return new Promise(resolve => {
    // const files = [file]
    // 这个好用压缩更小一些
    compress.compress([file], {
      size: 1, // the max size in MB, defaults to 2MB
      quality: .3, // the quality of the image, max is 1,
      maxWidth: 720, // the max width of the output image, defaults to 1920px
      maxHeight: 1920, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
      rotate: false, // See the rotation section below
    }).then(result => {
      const {data, ext} = result[0]
      const base64str = data
      const imgExt = ext
      const file = Compress.convertBase64ToFile(base64str, imgExt)
      resolve(file)
    })
  })
}

function uploadVideoFile(callback) {
  uploadVideo(file => {
    showLoading('上传中...')
    const fileSize = file.size / (1000 * 1000);
    if (fileSize > maxUploadFileSize) {
      loading.clear()
      Toast('文件超过100M请压缩后再上传')
      return
    }

    const formData = new window.FormData();
    formData.append('file', file, file.name)
    store.dispatch('uploadVideo', formData).then(data => {
      loading.clear()
      callback(data)
    }).catch(err => {
      Toast(err)
    })
  })
}

function isURL(str_url) {// 验证url
  const strRegex = "^((https|http|ftp|rtsp|mms)?://)"
    + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?"
    + '(([0-9]{1,3}.){3}[0-9]{1,3}'
    + "|" // 允许IP和DOMAIN（域名）
    + "([0-9a-z_!~*'()-]+\\.)*" // 域名- www.
    + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\\." // 二级域名
    + "[a-z]{2,6})" // first level domain- .com or .museum
    + "(:[0-9]{1,4})?" // 端口- :80
    + "((/?)|" // a slash isn't required if there is no file name
    + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  const re = new RegExp(strRegex);
  return re.test(str_url);
}

function getRootUrl() {
  const url = new URL(document.location.href)
  let result = url.protocol + "//" + url.hostname
  if (url.port !== '' && url.port !== '80') {
    result += ":" + url.port
  }

  return result
}

function trim(str, ext = ',') {
  if (str.substr(0, 1) === ext) {
    str = str.substr(1, str.length)
  }

  if (str.substr(str.length - 1, str.length) === ext) {
    str = str.substr(0, str.length - 1)
  }

  return str
}


function appSource() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return "ios";
  } else {
    return "android";
  }
}


export {
  BASE_URL,
  appSource,
  isURL,
  getRootUrl,
  getToken,
  setToken,
  uploadImage,
  checkPhone,
  uploadVideo,
  parseImag,
  formatDuration,
  copy,
  uploadVideoFile,
  uploadImageFile,
  trim,
  compressImage,// 压缩视频
}
